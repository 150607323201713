/**
 * vue-universal-actionbox
 * @version 0.4.0
 * @author Michal Langhoffer <langhoffer@avast.com>, Eliska Klimtova <klimtova@avast.com>, Ales Gabriel <gabriel@avast.com>
 * @requires avast.web.campaignMarkerCookie
 * @requires avast.jquery
 */

(function() {
	'use strict';

	avm.define('vue-action-boxes', (function() {
		'use strict';
		Vue.config.devtools = true;

		Vue.config.errorHandler = function(err, vm, info) {
			// err: error trace
			// vm: component in which error occured
			// info: Vue specific error information such as lifecycle hooks, events etc.
			console.log('errorHandler');
			console.log(1, err);
			console.log(2, vm);
			console.log(3, info);
			// TODO: Perform any custom logic or log to server
		};

		var $ = avm.require('jquery'),
			detectedOS = avastGlobals.detect.os.name.toLowerCase(),
			$vueActionBox = $('.js-vue-action-box'),
			staticPath = getStaticPath();

		// Return if no Action boxes present on page
		if ($vueActionBox.length === 0) {
			return false;
		}

		// Register Vue components globally
		Vue.component('toggler', {
			template: '#toggler',
			delimiters: ["<%","%>"],
			props: ['options', 'selected_index', 'selected_value', 'hide_toggler', 'horizontal_toggler', 'toggler_placeholder'],
			computed: {
				singleOption: function() {
					return !this.options.option_2;
				}
			},
			methods: {
				handleClick: function(event, indexValue) {
					this.$emit('change_data', indexValue);
				},
				// Set CSS classes according to specific rules.
				// Method is called from individual items.
				// Can't be a copmuted, because index from v-for is not returned to computed in parameter
				/**
				 * getTogglerCssClasses
				 * @param {Int} index Current selected option index
				 * @returns {Object} cssClass object. More info https://vuejs.org/v2/guide/class-and-style.html#Binding-HTML-Classes
				 */
				getTogglerCssClasses: function(index) {
					var isActive = false,
						isDisabled = false,
						optionKey = index ? Number(index.substring(7)) - 1 : null;

					// Set active if current option in toggler is selected.
					if (optionKey === this.selected_index) {
						isActive = true;
					}

					// Set disabled if only one option
					if (!this.options.option_2) {
						isActive = false;
						isDisabled = true;
					}

					return {
						active: isActive,
						disabled: isDisabled
					};
				}
			}
		});

		Vue.component('box', {
			delimiters: ["<%","%>"],
			props: ['set', 'iterator', 'options', 'selected_index', 'hide_desktop_title', 'hide_mobile_title', 'legal_gb_text', 'subscription_text', 'it_works_out', 'saving_compare_month', 'saving_compare_year', 'first_yr', 'first_2yrs', 'first_3yrs', 'per_year', 'per_2years', 'per_3years', 'button_default_text', 'price_for_month', 'price_for_first_year', 'price_for_first_2years', 'price_for_first_3years'],
			template: '#box',
			data: function() {
				return {
					optionData: this.options['option_' + (this.selected_index + 1)],
					pricelist: this.set[Object.keys(this.set)[this.iterator]],
				};
			},
			methods: {
				htmlDecode: function(input) {
					var e = document.createElement('div');
					e.innerHTML = input;
					// TODO - possible to only use with formatFlag type PARTS
					return e.childNodes.length === 0 ? '' : e.childNodes[0].innerText;
				},
				renderLabel: function(text) {
					var discountPrice = this.set[Object.keys(this.set)[this.iterator]].discountFormatted,
						discountPercentage = this.set[Object.keys(this.set)[this.iterator]].discountPercent;

					return text.replace('##discount_price##', discountPrice).replace('##discount_percentage##', discountPercentage);
				},
				setCampaignMarkerCookie: function() {
					var cartLinks = this.$el.querySelectorAll('[data-role="cart-link"]');

					// Update campaign marker data and href attributes with __trSrc cookie
					if (cartLinks.length) {
						this.campaignMarker.setCampaignMarkerCookie(cartLinks);
					}

					// update campaign marker data and href with AB test from google optimize editor
					if(avastGlobals.web.abtest !== undefined) {
						cartLinks.forEach(function(el) {
							var dataCM = el.getAttribute('data-campaign-marker').split('~'),
								hrefCM = el.getAttribute('href').split('~'),
								abTest = avastGlobals.web.abtest.ticketNumber,
								testVariant = avastGlobals.web.abtest.testVariant;

							dataCM[3] = abTest;
							dataCM[4] = testVariant;
							hrefCM[3] = abTest;
							hrefCM[4] = testVariant;
							el.setAttribute('data-campaign-marker', dataCM.join('~'))
							el.setAttribute('href', hrefCM.join('~'))
						})
					};
					return cartLinks;
				},
				footerText: function(text) {
					if (text === undefined) {
						return '<span class="subscription-trigger" data-target="#subscription-details-modal" data-toggle="modal" data-role="button" tabindex="0">'+this.subscription_text+'&nbsp;<span class="icon icon-info"></span></span>'
					}
					var textArray = text.split('</'),
						arrayLength = textArray.length,
						subscriptionEl = '<span class="subscription-trigger" data-target="#subscription-details-modal" data-toggle="modal" data-role="button" tabindex="0">'+this.subscription_text+'&nbsp;<span class="icon icon-info"></span></span>',
						result = text;

					if(/bi-cart-link|bi-download-link/.test(text)) {
						var subscriptionEl2 = '<span class="subscription-trigger" data-target="#subscription-details-modal" data-toggle="modal" data-role="button" tabindex="0">'+this.subscription_text+'&nbsp;<span class="icon icon-info"></span></span><br>',
						textArray = text.split('<a ');
						arrayLength = textArray.length;

						if (textArray[0].length === 0) {
							textArray[0] = subscriptionEl2;
							result = textArray.join('<a ')
						} else {
							if (/<\//.test(textArray[0])) {
								var elements = textArray[0].split('</'),
									elementsLength = elements.length;

								if (elements[elementsLength - 2].slice(-1) !== '.') {
									elements[elementsLength - 2] = elements[elementsLength - 2] + '. ';
									textArray[0] = elements.join('</') + subscriptionEl2;
									result = textArray.join('<a ');
								}
							} else {
								result = textArray[0].slice(-1) !== '.' ? textArray[0] + '. ' + subscriptionEl2 : textArray[0] + ' ' + subscriptionEl2 ;
							}
						}

					} else {
						if (arrayLength > 1) {
							var afterElement = textArray[arrayLength - 1].split('>');

							if (afterElement[1].trim().length === 0) {
								textArray[arrayLength - 2] = textArray[arrayLength - 2].slice(-1) !== '.' ? textArray[arrayLength - 2] + '.' : textArray[arrayLength - 2];
								result = textArray.join('</') + ' ' + subscriptionEl;
							} else {
								result = text.trim().slice(-1) !== '.'  ? text + '. ' + subscriptionEl : text + ' ' + subscriptionEl;
							}
						} else {
							result = text.slice(-1) !== '.' && text.trim() !== '' ? text + '. ' + subscriptionEl : text + ' ' + subscriptionEl;
						}
					}


					return result;
				},
				footerTextAlone: function() {
					return '<span class="subscription-trigger" data-target="#subscription-details-modal" data-toggle="modal" data-role="button" tabindex="0">'+this.subscription_text+'&nbsp;<span class="icon icon-info"></span></span>';
				},
				getClasses: function() {
					return (this.pricelist.priceFlag == 'M' ? 'row-month' : 'row-year') + ' ' + ('discountCoupon' in this.pricelist && this.pricelist.discountCoupon != '' ? 'row-discount' : '');
				}
			},
			mounted: function() {
				// Init avm Campaign Marker
				this.campaignMarker = avm.require('avast.web.campaignMarkerCookie')();

				// Update campaign marker data and href attributes with __trSrc cookie
				this.setCampaignMarkerCookie();
				if (this.$el.querySelector('[data-toggle="modal"][data-target]') !== null) {
					initModal(this.$el.querySelector('[data-toggle="modal"][data-target]'));
				}

			},
			updated: function() {
				// Update campaign marker data and href attributes with __trSrc cookie
				this.setCampaignMarkerCookie();
				this.optionData = this.options['option_' + (this.selected_index + 1)];
			},
			computed: {
				getButtonText: function() {
					var value = 
						typeof(this.optionData.button_text) !== 'undefined' && 
						this.optionData.button_text !== '' &&
						typeof(this.optionData.button_text['box' + (this.iterator + 1)]) !== 'undefined' &&
						this.optionData.button_text['box' + (this.iterator + 1)] !== ''
						? 
						this.optionData.button_text['box' + (this.iterator + 1)] : 
						this.button_default_text;
						
					return value;
				},
				compileOldPrice: function() {
					var price = this.set[Object.keys(this.set)[this.iterator]],
						priceInteger = price.priceIntegerPart,
						priceDecimalSeparator = price.decimalSeparator,
						priceDecimalPart = price.priceDecimalPart,
						currencySymbol = price.currencySymbol,
						priceFormat = price.priceFormat,
						oldPrice,
						oldPriceFormatted;

					oldPrice = priceInteger + (priceDecimalPart != '' ? (priceDecimalSeparator + priceDecimalPart) : '');
					oldPriceFormatted = priceFormat.replace('#c',currencySymbol).replace('#p',oldPrice);

					return oldPriceFormatted;
				},
				legalText: function() {
					// UK compliance GLOWEB-4625
					var pricelist = this.set[Object.keys(this.set)[this.iterator]];

					return this.legal_gb_text.replace('$$', pricelist.priceFor1year);
				},
				monthPrice: function() {
					var pricelist = this.set[Object.keys(this.set)[this.iterator]];
					if ('realPriceRoundedPerMonth' in pricelist && pricelist.realPriceRoundedPerMonth !== undefined) {
						return pricelist.realPriceRoundedPerMonth;
					} else {
						return false;
					}
				},
				priceForYear: function() {
					var price = this.set[Object.keys(this.set)[this.iterator]],
						text = this.first_yr;

					if ('discountCoupon' in price && price.discountCoupon != '') {
						switch (price.maintenance) {
							case '24':
								text = this.first_2yrs;
								break;
	
							case '36':
								text = this.first_3yrs;
								break;
						
							default:
								text = this.first_yr;
								break;
						}

						return text.replace('$old$',price.pricePerYearFormatted).replace('$new$',price.realPricePerYearFormatted);
					} else {
						switch (price.maintenance) {
							case '24':
								text = this.per_2years;
								break;
	
							case '36':
								text = this.per_3years;
								break;
						
							default:
								text = this.per_year;
								break;
						}

						return text.replace('$$',price.realPricePerYearFormatted);
					}

				},
				compareText: function() {
					return '<span class="subscription-trigger" data-target="#subscription-details-modal" data-toggle="modal" data-role="button" tabindex="0">'+ this.saving_compare_year.replace('$$', this.set[Object.keys(this.set)[this.iterator]].priceFor1year) + ' <span class="nowrap">' + this.subscription_text + '&nbsp;<span class="icon icon-info"></span></span></span>'
				}
			}
		});

		Vue.component('box-content', {
			delimiters: ["<%","%>"],
			props: ['content'],
			template: '#box-content',
			computed: {
				updateImageLinks: function() {
					return this.content.replace(/'i\//gm, '\'' + staticPath + 'i/');
				}
			}
		});

		// Installments
		Vue.component('installments', {
			delimiters: ['<%', '%>'],
			props: ['price', 'sr_inst_text', 'installments_placeholder'],
			template: '#installments',
			data: function () {
				return {}
			},
			computed: {
				installmentsPosition: function() {
					/*
					Detect the position of #i in string - is it before #p or after?
					Might be usefull if it will be needed to place installments on different place for different results.
					Currently not used.
					*/
					return this.price.installmentsFormat.indexOf('#i') > this.price.installmentsFormat.indexOf('#p') ? 'before' : 'after';
				}
			}
		});

		// Price row
		Vue.component('price-wrapper', {
			delimiters: ['<%', '%>'],
			props: ['options','price','oldprice','text_first_month', 'text_first_yr', 'text_first_2yrs', 'text_first_3yrs', 'text_year', 'text_month', 'text_2years', 'text_3years'],
			template: '#price-wrapper',
			data: function () {
				return {
				}
			},
			computed: {
				// position of currency symbol
				symbolShowPrefix: function() {
					return /^#c/.test(this.price.priceFormat);
				},
				symbol: function() {
					if (this.symbolShowPrefix) {
						return this.price.currencySymbol.replace('&nbsp;', '');
					} else {
						return '&nbsp;' + this.price.currencySymbol.replace('&nbsp;', '');
					}
				},
				decimal: function() {
					return this.price.decimalSeparator + this.price.realPriceDecimalPart;
				},
				showDecimal: function() {
					return this.price.realPriceDecimalPart !== '' ? true : false;
				},
				priceRowSize: function() {
					return this.price.realPriceIntegerPart.replace(this.price.thousandSeparator, " ").length > 5 ? 'price-row-medium' : '';
				},
				period: function() {
					if (this.price.priceFlag == 'M') {
						return this.text_month;
					} else {
						if ('discountCoupon' in this.price && this.price.discountCoupon !== '') {
							switch (this.price.maintenance) {
								case '1':
									return this.text_first_month;
		
								case '24':
									return this.text_first_2yrs;
		
								case '36':
									return this.text_first_3yrs;
						
								default:
									return this.text_first_yr;
							}
						} else {
							switch (this.price.maintenance) {
								case '1':
									return this.text_month;
		
								case '24':
									return this.text_2years;
		
								case '36':
									return this.text_3years;
						
								default:
									return this.text_year;
							}
						}
					}
					
				}
			},
			methods: {
				updatePeriodForUk: function() {
					if(avastGlobals.web.locale == 'en-gb') {
						if((!'priceFlag' in this.price || ('priceFlag' in this.price && this.price.priceFlag !== "M")) && this.price.maintenance !== '1') {
							if(this.price.maintenance === '36') {
								this.period = '/3 years';
							} else if (this.price.maintenance === '24') {
								this.period = '/2 years'
							} else {
								this.period = '/year'
							}
						}
					}
				}
			},
			updated: function(){
				this.updatePeriodForUk();
			},
			mounted: function() {
				this.updatePeriodForUk();
			}
		});

		// /Register Vue components globally

		// Init
		$vueActionBox.each(function(index, value) {
			var self = this,
				$self = $(self),
				settings = $self.data().settings;

			intializeVueInstance(index, self, settings);
		});
		// /Init

		/**
		 *
		 * @param {*} index
		 * @param {*} element
		 * @param {*} settings
		 * @returns {Object} vue instance
		 */
		function intializeVueInstance(index, element, settings) {
			// @todo : pricelist relies on option_1 will have pricelist
			var pricelist = settings.options.option_1.pricelist,
				platforms,
				boxCount = objectSize(pricelist),
				datasetsBoxes = getDatasetForBoxes(settings.options),
				selectedIndex = 0, // default is first option
				datasetBoxes = datasetsBoxes[0]; // default is first dataset

			// Default option pre-selection. This is different from default platform. This overrides detection
			if (typeof settings.default_option !== 'undefined') {
				// Convert string number to integer
				settings.default_option = parseInt(settings.default_option);
				// Assign default option
				selectedIndex	= settings.default_option - 1; // -1 is due to index counting

			// If platform detect enabled
			} else if (typeof settings.platform_detect !== 'undefined') {
				// Convert string boolean to boolean
				settings.platform_detect = (settings.platform_detect === 'true');
				// Get index accoding to detect
				platforms = getPlatformsArray(settings.options);
				selectedIndex = platforms.indexOf(detectedOS) > -1 ? platforms.indexOf(detectedOS) : platforms.indexOf(settings.default_platform);
			}

			// when all IFs resolved, assign data
			settings.boxCount 				= boxCount;
			settings.datasetsBoxes 			= datasetsBoxes; // This is data for all boxes
			settings.datasetBoxes 			= datasetBoxes; // This is data for changing boxes values
			settings.showRegularBoxes 		= false;
			settings.contentForBox			= '';
			settings.selectedIndex			= selectedIndex;
			settings.selectedValue			= '';
			settings.hide_toggler			= settings.hide_toggler === 'true';
			settings.horizontal_toggler		= settings.horizontal_toggler === 'true';
			settings.toggler_placeholder	= settings.toggler_placeholder === 'true';
			settings.hide_desktop_title		= settings.hide_desktop_title === 'true';
			settings.hide_mobile_title		= settings.hide_mobile_title === 'true';
			settings.hide_desktop_period	= settings.hide_desktop_period === 'true';

			// Get selected index from session storage, use default index in case of error or no previous record
			try {
				var sessionIndex = JSON.parse(sessionStorage.selectedIndex)[location.pathname]['vue-action-box-' + index];
				settings.selectedIndex = sessionIndex >= 0 ? sessionIndex : selectedIndex;
			} catch (silentError) {}

			window['vue-action-box-' + index] = new Vue({
				el: element,
				delimiters: ['<%', '%>'],
				data: function() {
					return settings;
				},
				computed: {
					eventData: function() {
						return new CustomEvent('actionbox-updated', {'detail': {'element':this.$el}});
					}
				},
				methods: {
					changeData: function (index) {
						this.$nextTick(function () { window.dispatchEvent(this.eventData) }) // send global event on toggler change

						//console.log('%cChange data : ', 'color: #ffff00', index);
						var choosenOption = this.options['option_' + (index + 1)],
							showRegularBoxes = typeof choosenOption.content === 'undefined' ? true : false;

						this.datasetBoxes = this.datasetsBoxes[index];
						this.showRegularBoxes = showRegularBoxes ? true : false;
						this.contentForBox = showRegularBoxes ? '' : convertStringToHTML(choosenOption.content);
						this.selectedIndex = index;
						this.selectedValue = choosenOption.toggler_text;

						// Custom toggler
						var instance = this;
						setTimeout(function() {
							customToggler(instance);
						}, 10);
					}
				},
				mounted: function() {
					// console.log('INSTANCE mounted', this.selectedIndex);
					this.changeData(this.selectedIndex);

					// if horizontal toggler enabled
					if (this.horizontal_toggler) {
						if (!this.options.option_2) {
							// Toggler is the default display
							this.horizontal_toggler = true;
						} else {
							// decide if to show it according to visual conditions
							this.horizontal_toggler = shouldDisplayHorizontalToggler(index);

							var _this = this;
							$(window).on('resize', function() {
								_this.horizontal_toggler = shouldDisplayHorizontalToggler(index);
							});
						}
					}

					this.$nextTick(function(){window.dispatchEvent(this.eventData)})
				},
				watch: {
					selectedIndex: function(newIndex) {
						// Create default session object
						var sessionObject = {};
						sessionObject[location.pathname] = {};
						// Load object from session storage and in case of failure, use default
						try {
							sessionObject = JSON.parse(sessionStorage.selectedIndex);
						} catch (silentError) {}
						// If there is no previous record of current path, create empty object value
						if (!sessionObject[location.pathname]) {
							sessionObject[location.pathname] = {};
						}
						// Store selected index of current box on current pathname so other pages won't break
						sessionObject[location.pathname]['vue-action-box-' + index] = newIndex;
						sessionStorage.selectedIndex = JSON.stringify(sessionObject);
					}
				}
			});

			return window['vue-action-box-' + index];
			// Update campaignMarker
		}

		/**
		 *
		 * @param {*} object
		 * @returns {Array}
		 */
		function getDatasetForBoxes(object) {
			var arr = [];

			for (var key in object) {
				if (typeof object[key].pricelist !== 'undefined') {
					arr.push(object[key].pricelist);
				} else {
					arr.push(null);
				}
			}

			return arr;
		}

		/**
		 *
		 * @param {*} object
		 * @returns {Int} size
		 */
		function objectSize(object) {
			var size = 0,
				key;

			for (key in object) {
				if (object.hasOwnProperty(key)) {
					size++;
				};
			}
			return size;
		}

		function convertStringToHTML(codeString) {
			return codeString.replace(new RegExp('&lt;','gm'), '<').replace(new RegExp('&gt;','gm'), '>')
		}

		function getStaticPath() {
			var path = $('link[href*=".css"]').attr('href');
			return path.split('c/')[0];
		}

		function getPlatformsArray(optionsObject) {
			var iterator = 1,
				result = [];

			for (var key in optionsObject) {
				result.push(optionsObject['option_' + iterator].platform);
				iterator++;
			}

			return result;
		}

		// This function detects custom toggle element in boxes and switches according to data attached to toggle elements
		function customToggler(instance) {
			var $instanceElement = $(instance.$el),
				$toggler = $instanceElement.find('.js-toggle-to-option');

			if ($toggler.length > 0) {
				$toggler.on('click', function(event) {
					var index = $(this).data('option') - 1; // must decrement index by 1 because options are using count from 1, not from 0

					event.preventDefault();
					instance.changeData(index);
				});
			}
		}

		/**
		 *
		 * @param {Int} index
		 * @returns {boolean}
		 */
		function shouldDisplayHorizontalToggler(index) {
			var $selfActionBox = $('.js-vue-action-box').eq(index),
				$selfActionBoxContainer = $selfActionBox.find('.js-actionbox-container'),
				$selfActionBoxContainerWidth = $selfActionBoxContainer.width(),
				$horizontalToggler = $selfActionBox.find('.js-horizontal-toggler'),
				$toggler = $horizontalToggler.find('.js-toggler'),
				isTogglerWidthAcceptable = false,
				togglerWidth = 0,
				togglerWidths = [],
				widestToggler;


			// there are maximum of 3 togglers
			if ($toggler.length < 4) {
				// longest toggler width less than 180px
				for (var i = 0; i < $toggler.length; i++) {
					togglerWidth = $toggler.eq(i).outerWidth();
					togglerWidths.push(togglerWidth);

					if (togglerWidth > 180) {
						isTogglerWidthAcceptable = false;
						break;
					} else {
						isTogglerWidthAcceptable = true;
					}
				}
			}

			// check if when setting of same widths for all togglers will be less than actionbox width
			if (isTogglerWidthAcceptable) {
				widestToggler = Math.max.apply(Math, togglerWidths);

				// check if when all togglers have same width if it will be less or same as actionbox container width
				// if yes, set same width for all togglers
				if ((widestToggler * $toggler.length) <= $selfActionBoxContainerWidth) {
					// set same width for all togglers
					$toggler.outerWidth(widestToggler);
				// else return false
				} else {
					isTogglerWidthAcceptable = false;
				}
			}
			return isTogglerWidthAcceptable;
		}

		function initModal(element) {
			element.addEventListener('click', function(){
				if (document.querySelector(element.getAttribute('data-target')) === null) {
					var contentHolder = document.querySelector('#body-inner'),
						targetModal = element.getAttribute('data-target'),
						xhr = new XMLHttpRequest();

					xhr.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {

							var container = document.createElement('div');
							container.innerHTML = this.responseText;
							contentHolder.append(container);
							$(targetModal).modal('show');
						}
					};
					// xhr.open("GET", 'https://' + avastGlobals.web.domain + '/' + avastGlobals.web.locale + '/' + targetModal.substring(1) + '?projectId[]=29965', true);
					xhr.open("GET", 'https://' + avastGlobals.web.domain + '/' + avastGlobals.web.locale + '/' + targetModal.substring(1), true);
					xhr.setRequestHeader('x-requested-with', 'XMLHttpRequest');
					xhr.send(null);
				}
			})
		}

		return {
			getDatasetForBoxes: getDatasetForBoxes,
			objectSize: objectSize,
			intializeVueInstance: intializeVueInstance,
			convertStringToHTML: convertStringToHTML,
			shouldDisplayHorizontalToggler: shouldDisplayHorizontalToggler
		};
	})());
})();
